<template>
  <div id="account-setting-lottoset" class="container-fluid">

    <div class="page-header mb-1">
      <strong>ตั้งค่าหวยชุด</strong>
    </div>

    <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-info">
      <li class="breadcrumb-item" v-for="breadcrumb in breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div v-if="markets.length" class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="market in markets" :key="market._id" class="nav-item">
            <a class="nav-link" :class="[{'active':tabActiveMarketId===market._id}]" href="#" target="_self" @click="tabActiveMarketId=market._id">{{ market.marketTitle }}</a>
          </li>
        </ul>
      </div>

      <div class="payrate-options p-1">
        <button
          v-for="rate in avaRates"
          class="btn m-1"
          :key="rate._id"
          :class="[{'btn-primary':tabActiveRateId===rate._id}, {'btn-light text-primary':tabActiveRateId!==rate._id}]"
          @click="tabActiveRateId=rate._id"
        >{{ rate.rateTitle }}</button>
      </div>

      <div class="p-2">
        <table class="table table-hover table-bordered">
          <thead class="alert-success">
            <tr>
              <th rowspan="2" class="text-center border-success">ราคา (บาท/ชุด)</th>
              <th :colspan="isCompany ? marketOpenBets.length+1 : marketOpenBets.length" class="text-center border-success">จ่าย (บาท)</th>
            </tr>
            <tr>
              <th
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-center border-success"
              >{{ bet.text }}</th>
              <th v-if="isCompany" width="70" class="text-center p-0 border-success">แก้ไข</th>
            </tr>
            <tr>
              <td class="p-1 border-success text-center bg-light">
                <b-form-input
                  v-if="isCompany"
                  size="sm"
                  :number="true"
                  :disabled="!permSetting"
                  class="text-center"
                  v-model="inputPrice"
                />
                <span v-else class="text-primary">{{ marPrice | amountFormat(2, '0.00') }}</span>
              </td>
              <td
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="p-1 border-success text-center bg-light"
              >
                <b-form-input
                  v-if="isCompany"
                  size="sm"
                  :number="true"
                  :disabled="!permSetting"
                  class="text-center"
                  v-model="inputPrizes[bet.code]"
                />
                <span v-else class="text-primary">{{ inputPrizes[bet.code] | amountFormat(2, '0.00') }}</span>
              </td>
              <td v-if="isCompany" width="70" class="text-center p-1 border-success bg-light">
                <b-button variant="success" size="sm" :disabled="!permSetting" @click="saveRate">บันทึก</b-button>
              </td>
            </tr>
          </thead>
        </table>

        <table class="table-accounts table table-hover table-bordered mb-0">
          <thead class="alert-warning">
            <tr>
              <th width="40" class="text-center border-left-thick" rowspan="3">ลำดับ</th>
              <th width="50" class="text-center" rowspan="2">เลือก</th>
              <th class="text-center" rowspan="3">
                ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th class="text-center" rowspan="3">ระดับ</th>
              <th class="text-center" rowspan="3">เปิด/ปิด</th>
              <th width="240" class="text-center border-left-thick border-right-thick" colspan="2">ค่าคอมฯ (บาท)</th>
              <th class="text-center border-right-thick" colspan="4">แบ่งหุ้น</th>
              <th width="70" class="text-center border-right-thick" rowspan="2">แก้ไข</th>
            </tr>
            <tr>
              <th width="120" class="text-center border-left-thick">ได้รับ</th>
              <th width="120" class="text-center border-right-thick">จ่าย</th>
              <th width="140" class="text-center">% ได้รับ</th>
              <th width="140" class="text-center">% แบ่งหุ้น</th>
              <th width="140" class="text-center">% เก็บของ</th>
              <th width="140" class="text-center border-right-thick">เก็บของเกิน</th>
            </tr>
            <tr>
              <th class="text-center">
                <b-form-checkbox :disabled="!permSetting" v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th class="text-right text-info border-left-thick">
                {{refRate.discount | amountFormat(2, '0.00')}}
              </th>
              <th class="p-1 border-right-thick">
                <b-form-input
                  size="sm"
                  :number="true"
                  :disabled="!bulkAccountIds.length || !permSetting"
                  v-model="inputBulkDiscount"
                />
              </th>
              <th class="text-right text-info">
                {{shareReceive | amountFormat(2, '0.00')}}
              </th>
              <th class="text-center text-info p-1">
                <b-form-select
                  v-model="bulkShare"
                  :disabled="!bulkAccountIds.length"
                  :options="shareOptions"
                  size="sm"
                ></b-form-select>
              </th>
              <th class="text-center text-info p-1">
                <div v-if="bulkAccountIds.length && bulkShare!==null" class="text-right">{{ (shareReceive - bulkShare) | amountFormat(2, '0.00') }}</div>
                <div v-else class="text-center">-</div>
              </th>
              <th class="text-center p-0 border-right-thick">
                <b-form-checkbox v-model="bulkOver" :disabled="!bulkAccountIds.length || bulkShare===null" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th class="text-center p-0 border-right-thick">
                <b-button variant="success" size="sm" :disabled="!bulkAccountIds.length || !permSetting" @click="saveBulk">บันทึก</b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id">
              <td class="text-center border-left-thick">{{ index+1 }}</td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="bulkAccountIds"
                  :value="item._id"
                  :disabled="!item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable || !permSetting"
                />
              </td>
              <td class="text-center">
                <div class="username">
                  <a v-if="item.role==='Agent' && item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable" href="#" @click="toggleChildAccount(item._id)">{{ item.user.username }}</a>
                  <span v-else>{{ item.user.username }}</span>
                </div>
                <small v-if="showName" class="text-secondary">({{ item.user.firstName }})</small>
              </td>
              <td class="text-center"><AccountRole :role="item.role" /></td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable"
                  name="check-button"
                  switch
                  @change="toggleChange(item)"
                  :class="[item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable ? 'text-success' : 'text-danger']"
                >
                  {{ item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td
                class="text-right border-left-thick"
                :class="[{'alert-dark': !item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}, {'alert-danger': item.sets[tabActiveMarketId].rates[tabActiveRateId].discount > refRate.discount}]">
                <span v-if="item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable">
                  {{refRate.discount | amountFormat(2, '0.00')}}
                </span>
                <span v-else>-</span>
              </td>
              <td
                class="text-right border-right-thick"
                :class="[{'alert-dark': !item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}, {'alert-danger': item.sets[tabActiveMarketId].rates[tabActiveRateId].discount > refRate.discount}]">
                <span
                  v-if="item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable"
                  :class="[{'text-danger': item.sets[tabActiveMarketId].rates[tabActiveRateId].discount > refRate.discount}]"
                >{{ item.sets[tabActiveMarketId].rates[tabActiveRateId].discount | amountFormat(2, '0.00') }}</span>
                <span v-else>-</span>
              </td>
              <td
                class="text-right"
                :class="[{'alert-dark': !item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]">
                <span v-if="item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable">
                  {{ shareReceive | amountFormat(2, '0.00') }}
                </span>
                <span v-else>-</span>
              </td>
              <td
                class="text-right"
                :class="[{'alert-dark': !item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]">
                <span v-if="item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable">
                  {{ item.sets[tabActiveMarketId].rates[tabActiveRateId].share.receive | amountFormat(2, '0.00') }}
                </span>
                <span v-else>-</span>
              </td>
              <td
                class="text-right"
                :class="[{'alert-dark': !item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]">
                <span v-if="item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable">
                  {{ (shareReceive - item.sets[tabActiveMarketId].rates[tabActiveRateId].share.receive) | amountFormat(2, '0.00') }}
                </span>
                <span v-else>-</span>
              </td>
              <td
                class="text-center border-right-thick"
                :class="[{'alert-dark': !item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]">
                <div v-if="item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable">
                  <i v-if="item.sets[tabActiveMarketId].rates[tabActiveRateId].share.over" class="fas fa-check fa-lg text-success"></i>
                  <i v-else class="fas fa-times fa-lg text-danger"></i>
                </div>
                <span v-else>-</span>
              </td>
              <td class="text-center p-1 border-right-thick" :class="[{'alert-dark': !item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]">
                <button :disabled="!item.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable || !permSetting" class="btn btn-sm btn-outline-primary" @click="edit(item)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td :colspan="12" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="alert alert-warning border-warning">ไม่มีข้อมูลหวยชุด</div>

    <EditSetsModal
      :is-show="isShowModal"
      :account="setAccount"
      :market="market"
      :price="marPrice"
      :refRate="refRate"
      :rate="rate"
      :refReceive="shareReceive"
      :refAccountId="refAccountId"
      @close="modalClosed" />
  </div>
</template>
<script>
import AccountSettingService from '@/services/AccountSettingService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import { marketTypes } from '@/configs/market.config'
import EditSetsModal from './components/EditSetsModal'

export default {
  name: 'AccountSettingLottoset',
  components: {
    EditSetsModal
  },
  data() {
    return {
      data: null,
      accountId: this.$store.state.account._id,
      tabActiveMarketId: null,
      tabActiveRateId: null,
      showName: false,
      bulkAccountIds: [],
      checkAll: false,
      inputBulk: {},
      inputBulkDiscount: null,
      inputPrice: null,
      inputPrizes: {},
      bulkShare: null,
      bulkOver: false,
      isShowModal: false,
      setAccount: null
    }
  },
  computed: {
    isCompany() {
      return this.$store.state.userProfile.role === 'Company'
    },
    currentAccountId() {
      return this.$store.state.account._id
    },
    breadcrumbs() {
      return this.data?.parents ?? []
    },
    parentAccountId() {
      if(this.breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.breadcrumbs[this.breadcrumbs.length - 1].accountId
      }
    },
    markets() {
      return this.data?.avaMarkets ?? []
    },
    market() {
      if(!this.tabActiveMarketId)
        return null

      return this.markets.find(market=>market._id===this.tabActiveMarketId)
    },
    comRates() {
      if(!this.tabActiveMarketId)
        return {}

      return this.data?.comRates?.[this.tabActiveMarketId]?.rates ?? {}
    },
    avaRates() {
      const marketRates = this.data?.refRates?.[this.tabActiveMarketId]?.rates ?? {}
      return Object.keys(marketRates ?? {})
      .map((rateId)=>{
        return {
          _id: marketRates[rateId].setsRateId,
          rateTitle: marketRates[rateId].rateTitle
        }
      })
    },
    rates() {
      const rates = (this.data?.sysRates || []).filter((r)=>{
        return r.marketSet === this.market?.marketSet
          && this.comRates?.[r._id]?.isAvailable
      })

      if(!this.tabActiveRateId || !rates.map(r=>r._id).includes(this.tabActiveRateId))
        this.tabActiveRateId = rates?.[0]?._id || null

      return rates
    },
    rate() {
      return this.rates.find((r)=>{
        return r._id === this.tabActiveRateId
      })
    },
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((o)=>{
        return this.isAvailable(o.code)
      })
    },
    refSetting() {
      return this.data?.refSetting ?? null
    },
    shareReceive() {
      if(!this.refSetting || !this.tabActiveMarketId || !this.tabActiveRateId)
        return 0

      return this.refSetting?.[this.tabActiveMarketId]?.rates[this.tabActiveRateId]?.share?.receive ?? 0
    },
    shareOptions() {
      if(!this.refSetting || !this.tabActiveMarketId || !this.tabActiveRateId)
        return []

      const maxShare = this.shareReceive || 0
      let sharePercents = []
      for(let i=maxShare; i>=0; i-=0.5) {
        sharePercents.push({
          text: i.toFixed(1),
          value: i
        })
      }

      sharePercents.push({
        text: 'ไม่ตั้งค่า',
        value: null
      })

      return sharePercents
    },
    items() {
      return (this.data?.accounts ?? []).filter((item)=>{
        return this.refSetting?.[this.tabActiveMarketId]?.rates?.[this.tabActiveRateId]?.isAvailable
      })
    },
    refSetting() {
      return this.data?.refSetting || {}
    },
    refMarket() {
      return this.refSetting?.[this.tabActiveMarketId] ?? {}
    },
    refRate() {
      return this.refMarket?.rates?.[this.tabActiveRateId] ?? {}
    },
    refAccountId() {
      return this.data?.refAccountId ?? null
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' // || permission?.MemberBetLimit === 'w'
    },
    changed() {
      return `${this.tabActiveMarketId}-${this.tabActiveRateId}`
    },
    marPrice() {
      return this.comRates?.[this.tabActiveRateId]?.price ?? 0
    }
  },
  watch: {
    checkAll(n, o) {
      this.bulkAccountIds = []
      if(n){
        this.bulkAccountIds = this.items
        .filter((acc)=>{
          return acc?.sets?.[this.tabActiveMarketId]?.rates?.[this.tabActiveRateId]?.isAvailable
        })
        .map(acc=>acc._id)
      }
    },
    changed() {
      this.resetInput()
      if(this.tabActiveMarketId && this.tabActiveRateId) {
        this.inputPrice = this.comRates?.[this.tabActiveRateId]?.price
        this.inputPrizes = this.marketOpenBets.reduce((prizes, o)=>{
          prizes[o.code] = this.comRates?.[this.tabActiveRateId]?.openBets?.[o.code]?.pay ?? 0
          return prizes
        }, {})
      }
    }
  },
  methods: {
    loadAccounts() {
      AccountSettingService.getAccountSettingSets(this.accountId)
      .then((response) => {
        if(response.success) {
          this.data = response.data
          this.resetInput()
          if(!this.tabActiveMarketId) {
            this.tabActiveMarketId = (this.data.avaMarkets[0]?._id ?? null)
          }
        }
      })
    },
    toggleChildAccount(accountId) {
      this.accountId = accountId
      this.loadAccounts()
    },
    isAvailable(key) {
      return this.market?.openBets?.[key] && this.rate?.openBets?.[key]?.isAvailable
    },
    toggleChange(account) {
      this.save({
        accountIds: [account._id],
        refAccountId: this.refAccountId,
        marketId: this.tabActiveMarketId,
        rateId: this.tabActiveRateId,
        update: {
          isAvailable: account.sets[this.tabActiveMarketId].rates[this.tabActiveRateId].isAvailable,
          share: account.sets[this.tabActiveMarketId].rates[this.tabActiveRateId].share
        }
      })
    },
    saveBulk() {

      const numberPattern = /^-?\d+(\.\d{1,2})?$/
      if(numberPattern.test(this.inputBulkDiscount) && this.inputBulkDiscount > this.refRate.discount) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: `กรุณากรอกค่าคอมฯ น้อยกว่าหรือเท่ากับ ${this.refRate.discount}`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }else
      if(this.inputBulkDiscount && !numberPattern.test(this.inputBulkDiscount)) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: `ค่าคอมฯต้องเป็นตัวเลขเท่านั้น`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.inputBulkDiscount = null
        })
      }

      const updateData = {
        accountIds: this.bulkAccountIds,
        refAccountId: this.data.refAccountId,
        marketId: this.tabActiveMarketId,
        rateId: this.tabActiveRateId,
        update: {
          discount: this.inputBulkDiscount,
          share: {
            receive: this.bulkShare,
            referral: this.shareReceive - this.bulkShare,
            over: this.bulkOver
          }
        }
      }

      this.save(updateData)
    },
    save(data) {
      AccountSettingService.updateAccountSettingSets(data)
      .then((response) => {
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
          this.resetInput()
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ไม่สำเร็จ!',
          text: e?.message ?? 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadAccounts()
      })
    },
    edit(account) {
      this.setAccount = account
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.setAccount = null
      this.isShowModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    resetInput() {
      this.checkAll = false
      this.bulkAccountIds = []
      this.inputBulk = {}
      this.inputBulkDiscount = null
      this.bulkShare = null
      this.bulkOver = false
    },
    saveRate() {
      AccountSettingService.updateAccountSetsRate({
        marketId: this.tabActiveMarketId,
        rateId: this.tabActiveRateId,
        price: this.inputPrice,
        prizes: this.inputPrizes
      })
      .then((response) => {
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ไม่สำเร็จ!',
          text: 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadAccounts()
      })
    }
  },
  mounted() {
    this.loadAccounts()
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #FFF;
  border-radius: 10px;
}

.nav-link {
  cursor: pointer;
}

.table-accounts {
  thead, tbody {
    tr {
      th, td {
        border-color: #ffc107 !important;

        &.border-right-thick {
          border-right-width: 2px;
        }

        &.border-left-thick {
          border-left-width: 2px;
        }
      }
    }
  }
}

.table {
  thead {
    tr {
      th, td {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      th, td {
        vertical-align: middle;
        text-align: right;
        line-height: 1;

        div.username {
          font-size: 110%;
        }
        small {
          font-size: 75%;
        }
      }
    }
  }
}

.limit-options {
  button {
    width: 150px;
  }
}
</style>
